.single_product_container {
  display: flex;
  padding: 30px 5vw;
}

.single_product_left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 100px; */
}

.single_product_center {
  flex: 3;
  z-index: 9;
}

.single_product_right {
  flex: 3;
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  overflow-y: auto;
  height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.single_product_right::-webkit-scrollbar {
  display: none;
}

.single_product_multi_image {
  width: 100px;
  padding: 5px;
}

.single_product_multi_image img {
  width: 100%;
  object-fit: cover;
}
.activeImage {
  border: 0.1rem solid var(--orange-color);
}

.single_product_seller {
  display: flex;
}

.single_product_seller_detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.single_product_seller img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  object-fit: cover;
  object-position: top;
}
#single_product_seller_sq {
  width: 150px;
  height: 150px;
  margin-right: 20px;
  border-radius: 0;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
}
.single_product_seller_name {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  text-transform: capitalize;
}

.single_product_sold_status {
  display: flex;
  margin-top: 15px;
}
.single_produc_sold {
  margin-right: 50px;
}

.single_produc_sold svg {
  margin-right: 5px;
  color: var(--orange-color);
}

.single_produc_status {
  border: 0.1rem solid var(--orange-color);
  border-radius: 10px;
  color: var(--orange-color);
  padding: 0 10px;
}

.single_product_actions {
  position: relative;
  display: flex;
  align-items: center;

  margin-top: 30px;
  margin-bottom: 10px;
}

.single_product_actions svg {
  font-size: 20px;
}

.single_product_actions svg:hover {
  color: var(--orange-color);
}

.sp_name {
  font-weight: 500;
  margin-top: 10px;
  font-size: 25px;
  text-transform: capitalize;
  font-family: "Absentiasans";
}

.select_size_header {
  text-transform: capitalize;
  margin-right: 10px;
  margin: 10px 0;
}

.flexSelect {
  display: flex;
}

.sp_select_size_btn {
  border: 2px solid var(--orange-color);
  border-radius: 50%;
  width: 35px;
  font-size: 14px;
  height: 35px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sp_btn_checked {
  background: var(--orange-color);
  color: white;
}

.sp_price_detail {
  display: flex;
  align-items: center;
}

.sp_actual_price {
  font-weight: bold;
  margin-right: 20px;
  font-size: 25px;
}

.sp_discount_price {
  margin-right: 20px;
  text-decoration: line-through;
  font-size: 20px;
  color: #5b5b5b;
}

.sp_discount {
  font-size: 12px;
  color: var(--orange-color);
}

.sp_btn {
  margin: 20px 0;
}

.sp_cart_btn {
  width: 100%;
  background: var(--orange-color);
  color: white;
  border: 0;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  margin-bottom: 20px;
}

.sp_cart_btn:hover {
  background: var(--malon-color);
}
.sp_wishlist_btn {
  width: 100%;
  background: none;
  font-weight: bold;
  font-size: 18px;
  color: var(--orange-color);
  border: 0.1rem solid var(--orange-color);
  padding: 5px;
  text-transform: uppercase;
  border-radius: 10px;
}

.sp_wishlist_btn:hover {
  color: var(--malon-color);
  border: 0.1rem solid var(--malon-color);
}

.sp_more_detail {
  display: flex;
  padding: 15px;
  flex-direction: column;
}

.sp_detail_title {
  margin-bottom: 10px;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
}

.sp_detail_title::before {
  content: " ";
  width: 10px;
  height: 10px;
  border-bottom: 1px solid;
  border-left: 1px solid;
  transform: rotate(-45deg) translateY(-50%);
  position: absolute;
  top: 50%;
  right: 20px;
}

.sp_detail_section.active .sp_detail_title::before {
  content: " ";
  transform: rotate(134deg);
}

.sp_detail_section.active .sp_detail_contail {
  height: auto;
  margin-bottom: 20px;
  margin-left: 20px;
}

.sp_detail_contail {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
}

.sp_detail_section {
  width: 100%;
}

.sp_condition_cont {
  display: flex;
}
.sp_condition {
  text-transform: uppercase;
  background: var(--malon-color);
  padding: 0 10px;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.orange-color {
  color: var(--orange-color);
}
span.active2 > div {
  z-index: 8;
}
span.active2 > div > button:nth-child(1) {
  opacity: 1;
  top: -30px;
  right: 80%;
}
span.active2 > div > button:nth-child(2) {
  opacity: 1;
  top: -30px;
  right: 70%;
}
span.active2 > div > button:nth-child(3) {
  opacity: 1;
  top: -30px;
  right: 60%;
}
span.active2 > div > button:nth-child(4) {
  opacity: 1;
  top: -30px;
  right: 50%;
}
span.active2 > div > button:nth-child(5) {
  opacity: 1;
  top: -30px;
  right: 40%;
}
span.active2 > div > button:nth-child(6) {
  opacity: 1;
  top: -30px;
  right: 30%;
}
span.active2 > div > button:nth-child(7) {
  opacity: 1;
  top: -30px;
  right: 20%;
}

@media (max-width: 992px) {
  .single_product_container {
    flex-direction: column;
  }
  .single_product_left {
    display: none;
  }
  .single_product_center {
    display: none;
  }
  .single_product_sold_status {
    justify-content: space-between;
  }
  .single_product_right {
    padding-left: 10px;
    padding-right: 10px;
  }
  .single_product_actions {
    display: flex;
    justify-content: space-between;
    padding: 0 10vw;
  }

  span.active2 > div > button:nth-child(1) {
    right: -13%;
  }
  span.active2 > div > button:nth-child(2) {
    right: 75%;
  }
  span.active2 > div > button:nth-child(3) {
    right: 60%;
  }
  span.active2 > div > button:nth-child(4) {
    right: 45%;
  }
  span.active2 > div > button:nth-child(5) {
    right: 30%;
  }
  span.active2 > div > button:nth-child(6) {
    right: 15%;
  }
  span.active2 > div > button:nth-child(7) {
    right: 0%;
  }
}
