.newsletter_container {
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.newsletter_desc {
  background: none;
}

.newsletter_input {
  width: 50%;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
}

.newsletter_input input {
  border: none;
  flex: 8;
  padding-left: 20px;

  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}
.newsletter_input input:focus-visible {
  outline: none;
}

.newsletter_input button {
  flex: 2;
  background: var(--malon-color);
  color: white;
  border: none;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  border-left: 0 !important;
}

.newsletter_input button:hover {
  background: var(--orange-color);
  border: 1px solid var(--malon-color);
  color: var(--malon-color);
}

@media (max-width: 992px) {
  .newsletter_desc {
    text-align: center;
    color: black;
  }
  .malon {
    color: var(--malon-color);
  }
  .newsletter_desc b {
    font-size: 12px;
    color: black;
  }
  .newsletter_input {
    width: 90%;
  }
  .newsletter_container {
    padding-top: 10px;
  }
}
