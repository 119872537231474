.CategoryListing_section {
  display: flex;
  margin: 10px 0;
}

.categorylisting_img {
  position: relative;
  width: 240px;
  height: 240px;
  background: white;
  border-radius: 5px;
}

.categorylisting_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
}

.catelisting_container {
  position: relative;
  margin-right: 15px;
  height: 240px;
}

.categorylisting_title {
  position: absolute;
  bottom: 0;
  right: 0;
  color: var(--malon-color);
  display: flex;
  justify-content: center;
  background: white;
  width: 170px;
  border: 1px solid var(--malon-color);
  border-radius: 5px;
  text-transform: capitalize;
  cursor: pointer;
}

.categorylisting_title:hover {
  background: var(--malon-color);
  color: white;
}

.CategoryListing_item {
  display: flex;
  overflow-x: auto;
  flex: 4;
  height: 250px;
}

.CategoryListing_item::-webkit-scrollbar {
  height: 0.5rem;
}

.CategoryListing_item::-webkit-scrollbar-track {
  margin: 10px 0;
  border-radius: 100vw;
}
.CategoryListing_item::-webkit-scrollbar-thumb {
  background: white;

  border-radius: 100vw;
}

.product-title-Listing {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.product-title-Listing p {
  padding: 0 5vw;
  max-width: 450px;
  font-family: ChronicleText;
  text-align: justify;
}

.product-categorylisting {
  padding: 0 3vw 0 5vw;
  font-size: 50px;
  margin-bottom: 30px;
  text-transform: capitalize;
  position: relative;
}

.product-categorylisting::after {
  position: absolute;
  content: "";
  left: 10vw;
  bottom: -4px;
  height: 2px;
  width: 70px;
  background-color: var(--malon-color);
}

@media (max-width: 992px) {
  .CategoryListing_section {
    flex-direction: column;
    margin: 10px 0;
  }
  .product-title-Listing p {
    max-width: 400px;
  }
  .product-categorylisting {
    margin-bottom: 10px;
    font-size: 30px;
  }
  .CategoryListing_item {
    margin-left: 20px;
  }
}
