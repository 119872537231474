/* CSS */

.padtop {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.paddown {
  padding-bottom: 0.75rem;
}
.center-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.center-item .main-item {
  position: relative;
  height: 325px;
  display: flex;
  align-items: center;
}

.center-item .main-item img {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.center-item .main-item-1 {
  height: 650px;
}

.center-item .main-item-text {
  position: absolute;
  left: 50px;
  max-width: 150px;
  background: none;
}

.center-item .main-item-1 .main-item-text {
  position: absolute;
  left: 30px;
  max-width: 40%;
  background: none;
  margin-bottom: 15px;
}

.center-item .main-item-text h1 {
  color: #000;
  margin-bottom: 5px;
  background: none;
  font-weight: 800;
}

.center-item .main-item-text p {
  margin-bottom: 10px;
  background: none;
}

.center-item .main-item-text h4 {
  color: white;
  font-weight: 700;
  text-shadow: #000 1px 0 10px;
  background: none;
}

.center-item .main-item-text a {
  text-decoration: none;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  padding: 0 0 3px;
  display: inline-block;
  background: none;
}
.main-item-text > a:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: var(--orange-color);
  content: "";
}

.main-item-text .search-btn1 {
  padding: 10px 30px;
  border-radius: 25px;
}

.new-product {
  padding-top: 60px;
  padding-bottom: 50px;
}

.new-product .section-title {
  padding-bottom: 45px;
}

.new-product .section-title h4 {
  columns: #000;
  font-weight: 600;
  text-transform: capitalize;
  position: relative;
  display: inline;
}

.new-product .section-title h4:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -4px;
  height: 2px;
  width: 70px;
  background-color: var(--orange-color);
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.spad {
  padding-top: 20px;
  padding-bottom: 10px;
}
.property_gallery {
  padding: 0 10vw;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.product_item {
  flex: 0 0 auto;
  width: 214px;
  height: 450px;
  margin-bottom: 35px;
  margin-right: 20px;
}

.product_item_pic {
  position: relative;
  overflow: hidden;
}

.product_item_pic img {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.product_item_pic .label {
  position: absolute;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  padding: 2px 8px;
  text-transform: uppercase;
  left: 10px;
  top: 10px;
}

.product_item_pic .label.new {
  background-color: var(--orange-color);
}

.product_item_text {
  text-align: center;
  padding-top: 22px;
}
.product_item_text h6 a {
  font-size: 14px;
  color: #000;
  text-decoration: none;
}

.product_item_text .rating {
  line-height: 18px;
  margin-bottom: 5px;
}
.product_item_text .rating i {
  font-size: 10px;
  color: var(--orange-color);
  margin-right: -4px;
}
.rating span {
}
.product_item_text .rating i:last-child {
  margin-right: 0;
}

.product_item_text .product_price {
  color: #000;
  font-weight: 600;
}

.product_hover {
  z-index: 3;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 30px;
  text-align: center;
  background: none;
}
.product_hover li {
  background: none;
  list-style: none;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 100px;
  opacity: 0;
}
.product_hover li:last-child {
  margin-right: 0;
}

.product_hover li:hover span {
  background-color: var(--orange-color);
}
.product_hover li:hover span svg {
  color: #fff;
  transform: rotate(360deg);
}

.product_hover li span {
  font-size: 18px;
  color: #000;
  display: block;
  width: 45px;
  height: 45px;
  background-color: #fff;
  line-height: 45px;
  border-radius: 50px;
  box-shadow: 0 0 25px rgba(24, 24, 24, 0.4);
  transition: all 0.5s;
}

.product_hover li span svg {
  position: relative;
  display: inline-block;
  transform: rotate(0);
  transition: all 0.3s;
  background: none;
  color: var(--orange-color);
}

.product-image1:hover .product_hover li {
  opacity: 1;
  top: 0;
}

.product_hover li:nth-child(1) {
  transition: all 0.4s ease 0.15s;
}
.product_hover li:nth-child(2) {
  transition: all 0.4s ease 0.15s;
}
.product_hover li:nth-child(3) {
  transition: all 0.4s ease 0.2s;
}
.product_hover li:nth-child(4) {
  transition: all 0.4s ease 0n 25s;
}

.product_item_pic .label.stockout {
  background-color: #111111;
}
.product_item_pic .label.sale {
  background-color: var(--orange-color);
}

.product_item_text .product_price span {
  font-size: 14px;
  color: #b1b0b0;
  text-decoration: line-through;
  margin-left: 4px;
}

.banner {
  position: relative;
}

.bg-img {
  background-image: url(http://localhost:3000/images/t2.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
}

.banner_text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin-top: 30px;
  background: none;
  text-shadow: #000 1px 0 10px;
}

.banner_text span {
  font-size: 16 !important;
  font-weight: 700;
  color: var(--orange-color);
  text-transform: uppercase;
  background: none;
}

.banner_text h1 {
  font-size: 70px;
  background: none;
  margin-bottom: 1px;
  font-weight: bold;
  line-height: 60px;
  color: #fff;
}

.banner_text a {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  padding: 0 0 3px;
  display: inline-block;
  color: #fff;
  background: none;
}

.banner_text a:after,
.c:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background: var(--orange-color);
  content: "";
}

.banner_slider {
  text-align: center;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #b1b0b0 !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 20px;
  background: var(--orange-color) !important;
}

.banner_item {
  position: relative;
  overflow: hidden;
}

.banner_item .banner_image {
  position: relative;
  height: 600px;
  display: flex;
  align-items: center;
}

.banner_item .banner_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: bottom;
}

.small-button {
  margin: 5px 0px;
  background-color: var(--orange-color);
  border-radius: 100px;
  color: white;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 15px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.hot-deals-title {
  font-weight: bold;
  font-size: 18px;
}

.hot-deal-price {
  margin-top: 0.5rem;
}
.small-button:hover {
  transform: scale(1.05) rotate(-1deg);
}
ls {
  height: 90px;
  width: 120px;
}

.hot-deals-img {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin-left: 10px;
}

.hot-deal-detail {
  margin-left: 15px;
}
.hot-deal-name {
  font-weight: 600;
  font-size: 15px;
}
.hot-deal-description {
  font-size: 10px;
}

.categories ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.categories ul li {
  padding: 0px;
  margin: 17px 35px;
  font-weight: 500;
}

.main-item-img {
  border-radius: 10px;
}

/* ****************product slide styles**************** */

.product1 {
  position: relative;
  overflow: hidden;
  padding: 10px 0;
}
.product-category1 {
  padding: 0 5vw;
  font-size: 50px;
  margin-bottom: 20px;
  text-transform: capitalize;
  position: relative;
}

.product-category1::after {
  position: absolute;
  content: "";
  left: 10vw;
  bottom: -4px;
  height: 2px;
  width: 70px;
  background-color: var(--orange-color);
}

.product-container1 {
  margin: 0 5vw;
  display: flex;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.smooth1 {
  transition: all 0.5s ease-in-out;
}

.product-container1::-webkit-scrollbar {
  display: none;
}

.product-card1 {
  flex: 0 0 auto;
  width: 240px;
  height: 500px;
}

.product-image1 {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.product-thumb1 {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.discount-tag1 {
  position: absolute;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  color: var(--orange-color);
  right: 10px;
  top: 10px;
  text-transform: capitalize;
}

.card-btn1 {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  width: 90%;
  text-transform: capitalize;
  border: none;
  outline: none;
  background: #fff;
  color: var(--orange-color);
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;
  opacity: 0;
}

.product-card1:hover .card-btn1 {
  opacity: 1;
}

.card-btn1:hover {
  background: var(--orange-color);
  color: inherit;
}

.product-info1 {
  width: 100%;
  max-height: 100px;
  padding-top: 10px;
}

.product-brand1 {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-brand1 a {
  font-family: "Absentiasans";
}

.product-short-desc1 {
  width: 100%;
  height: 16px;
  line-height: 20px;
  overflow: hidden;
  opacity: 0.5;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.price1 {
  font-weight: 900;
  font-size: 20px;
}

.actual-price1 {
  margin-left: 20px;
  color: var(--orange-color);
  text-decoration: line-through;
}

.pre-btn1,
.next-btn1 {
  border: none;
  width: 5vw;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  align-items: center;
  cursor: pointer;
  z-index: 8;
  background: none;
}

.btn-cont {
  align-items: center;
}
.pleft {
  margin-left: 1rem;
}

.pre-btn1 {
  left: 0;
}
.next-btn1 {
  right: 0;
}

.pre-btn1 i,
.next-btn1 i {
  opacity: 0.2;
  font-size: 40px;
}

.pre-btn1:hover i,
.next-btn1:hover i {
  opacity: 1;
}

.carl {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
}

.left-side1 {
  width: 15vw;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  align-items: center;
}

.logo1 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2vw;
  position: absolute;
  top: 4vw;
  left: -1.5vw;
  transform: rotate(-90deg);
  user-select: none;
}

sm-product1 {
  width: 100%;
  height: auto;
}

.product-index1 {
  font-size: 2vw;
  text-align: center;
}

.sm-product-img-container1 {
  position: absolute;
  margin: 0.5vw 0;
  height: 15vw;
}

.sm-product-img1 {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.sm-product-desc1 {
  text-align: right;
  padding: 0 0.4vw;
  line-height: 1.5vw;
  font-size: 1vw;
  color: rgba(0, 0, 0, 0.5);
}

.social-link1 {
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translate(-50%);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 2;
  cursor: pointer;
}

.discount_pic {
  min-width: 100%;
  height: 100%;
}
.discount_pic img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.discount_text {
  background-color: #f4f4f4;
  height: 100%;
  padding: 75px 90px 50px;
}

.discount_text a {
  color: var(--malon-color);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  padding: 0 0 3px;
  display: inline-block;
}

.discount_text .discount_text_title {
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
  background: none;
}

.discount_text .discount_text_title:after {
  content: "";
  position: absolute;
  left: 50%;
  top: -38px;
  height: 183px;
  transform: translateX(-10%);
  width: 220px;
  background-color: #fff;
  z-index: -1;
  margin-left: -90px;
}

.discount_text .discount_text_title h5 {
  background: none;
  color: #000;
}

.discount_text .discount_text_title span {
  font-size: 20px;
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  background: none;
}

.discount_text .discount_text_title h2 {
  font-size: 55px;
  color: var(--orange-color);
  margin-bottom: 10px;
  background: none;
}

.discount_text .discount_text_title h2 {
  color: var(--orange-color);
  font-weight: 700;
  background: none;
}

.discount_text .discount_text_title h2 span {
  font-size: 14px;
  color: #000;
  margin-right: 5px;
  background: none;
}

.discount_countdown {
  text-align: center;
  margin-bottom: 10px;
  background: none;
}

.discount_countdown .countdown_item {
  margin-bottom: 15px;
  float: left;
  width: 25%;
  background: none;
}

.discount_countdown .countdown_item:last-child {
  margin-right: 0;
}

.discount_countdown .countdown_item span {
  font-size: 30px;
  font-weight: 600;
  display: inline-block;
  background: none;
}

.discount_countdown .countdown_item p {
  margin-bottom: 0;
  display: inline-block;
  font-weight: 500;
  background: none;
}

.c {
  margin-left: 45%;
  background: none;
  color: #000;
}
.shop-method-area {
  display: flex;
  flex-direction: column;
}

.shop-method-area .single-method svg {
  font-size: 45px;
  color: var(--orange-color);
  margin-bottom: 15px;
}

.shop-method-area .single-method h6 {
  color: inherit;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.shop-method-area .single-method p {
  font-size: 16px;
}

.mb-40 {
  margin-bottom: 40px;
}

form .mb-3 button.search-btn1 {
  padding: 10px 30px;
}

form .mb-3 a,
.fade a,
.simple_link {
  color: var(--orange-color);
  font-size: 13px;
  font-weight: 600;
}

form .mb-3 a:hover,
.simple_link:hover,
.fade a:hover {
  color: var(--malon-color);
}

.slanted {
  position: relative;
  width: 100%;
  background: var(--orange-color);
  z-index: 5;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 100px;
}

.slanted:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: inherit;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transform-origin: top left;
  transform: skewY(4deg);
}

.slanted2 {
  position: relative;
  width: 100%;
  background: var(--malon-color);
  color: white;
  box-sizing: border-box;
  z-index: 5;
  padding: 20px;
  margin-bottom: 100px;
}

.slanted2:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: inherit;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transform-origin: top right;
  transform: skewY(4deg);
}

@media (max-width: 992px) {
  .banner_item .banner_image {
    height: 300px;
  }
  .pre-btn1,
  .next-btn1 {
    display: none;
  }
  .center-item .main-item-text {
    left: 10px;
  }
  .overlay {
    position: absolute;
    width: 95%;
    height: 95%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 2;
    cursor: pointer;
  }

  .center-item .main-item img {
    object-position: left;
    left: 10px;
  }
  .center-item .main-item-1 .main-item-text {
    left: 10px;
  }
  .product-card1 {
    width: 162px;
    height: 342px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .center-item {
    margin-bottom: 10px;
  }
  .product-category1 {
    margin-bottom: 10px;
    font-size: 30px;
  }
  .product-brand1 {
    font-size: 15px;
  }
  .product-short-desc1 {
    font-size: 12px;
  }
  .price1 {
    font-size: 16px;
  }

  .actual-price1 {
    font-size: 16px;
  }
  .product1 {
    padding: 10px 0;
  }

  .discount-tag1 {
    font-size: 13px;
  }

  .product-image1 {
    height: 270px;
  }
  .product-thumb1 {
    width: 100%;
    height: 270px;
    object-fit: cover;
  }
  .center-item .main-item {
    height: 325px;
  }
  .discount_text {
    padding: 30px !important;
  }
  .discount_text .discount_text_title {
    margin-bottom: 30px;
  }
  .discount_countdown .countdown_item {
    display: flex;
    flex-direction: column;
  }
  .discount_text .discount_text_title:after {
    top: -18px;
  }
}
